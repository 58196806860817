.share {
  position: absolute;
  top: 40px;
  left: 40px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.12s ease-in-out, visibility 0s 0.12s;

  .is-modal & {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.12s ease-in-out 0.5s, visibility 0s 0.5s;
  }

  &.is-open {
    &.is-copied {
      .share__tag {
        opacity: 1;
        visibility: visible;
        transform: scale(0.6) rotate(-20deg);
      }
    }

    .share__toggle {
      transform: scale(0.5) !important;
      border: 0 none;

      img {
        &:first-of-type {
          transform: translateY(-50%) translateX(-50%) scale(0) rotate(-75deg) ;
          opacity: 0;
        }

        &:last-of-type {
          transform: translateY(-50%) translateX(-50%) scale(1) rotate(0);
          opacity: 1;
        }
      }
    }

    .share__list {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.15s ease-in-out, visibility 0s 0s;

      li {
        transform: translateX(0);
      }
    }
  }

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 94px;
    height: 94px;
    border: 1px solid #fff;
    border-radius: 50%;
    appearance: none;
    outline: none;
    box-shadow: none;
    background: transparent;
    transition: transform 0.15s ease-in-out, border 0.15s ease-in-out;
    will-change: transform;
    cursor: pointer;

    &:hover {
      transform: scale(1.1) rotate(-15deg);
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      transition: opacity 0.15s ease-in-out, transform 0.3s ease-in-out;

      &:last-of-type {
        opacity: 0;
        transform: translateY(-50%) translateX(-50%) scale(0) rotate(-75deg);
      }
    }
  }

  &__list {
    list-style: none;
    position: absolute;
    top: 20px;
    left: 94px;
    margin: 0;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.15s ease-in-out, visibility 0s 0.15s;

    li {
      position: absolute;
      transform: translateX(-24px);
      transition: transform 0.33s ease-out;

      @for $i from 0 through 2 {
        &:nth-child(#{$i + 1}) {
          left: 60px * $i;
          transition-delay: 0.04s * $i;
        }
      }
    }
  }

  &__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border: 1px solid #fff;
    border-radius: 50%;
    appearance: none;
    outline: none;
    box-shadow: none;
    background: transparent;
    transition: transform 0.15s ease-in-out;
    will-change: transform;
    cursor: pointer;

    &:hover {
      transform: scale(1.1) rotate(-15deg);
    }
  }

  &__icon {
    position: relative;
    display: block;
    width: 24px;
    height: 24px;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      fill: #fff;
    }
  }

  &__tag {
    display: inline-block;
    position: absolute;
    top: 0px;
    left: 250px;
    width: auto;
    height: 38px;
    background-color: #4CE91A;
    -webkit-border-radius: 3px 4px 4px 3px;
    -moz-border-radius: 3px 4px 4px 3px;
    border-radius: 3px 4px 4px 3px;
    border-left: 1px solid #4CE91A;

    /* This makes room for the triangle */
    margin-left: 19px;
    color: #151515;
    font-family: 'Aeonik Pro', sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 38px;
    padding: 0 10px 0 10px;

    /* Transformation */
    opacity: 0;
    visibility: hidden;
    transform: scale(0.5);
    transform-origin: left center;
    transition: transform 0.2s ease-in-out;

    /* Makes the triangle */
    &:before {
      content: "";
      position: absolute;
      display: block;
      left: -19px;
      width: 0;
      height: 0;
      border-top: 19px solid transparent;
      border-bottom: 19px solid transparent;
      border-right: 19px solid #4CE91A;
    }

    /* Makes the circle */
    &:after {
      content: "";
      background-color: #151515;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      display: block;
      position: absolute;
      left: -6px;
      top: 15px;
    }
  }
}