.modal {
  position: absolute;
  top: 20px;
  left: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  background: #151515;
  border-radius: 32px;
  color: #fff;
  z-index: 999999999;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: scale(0.96);
  transform-origin: center;
  transition: opacity 0.4s ease-out, visibility 0s 0.4s, transform 0.2s ease-out;
  will-change: opacity, visibility;
  overflow: hidden;

  &.is-loaded {
    .modal__loading {
      opacity: 0;
      visibility: hidden;
    }

    .modal__begin {
      opacity: 1;
      visibility: visible;
    }
  }

  &.is-visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: opacity 0.4s ease-in, visibility 0s 0s, transform 0.2s ease-out;
    pointer-events: initial;
  }

  &.is-modal {
    .modal__begin {
      opacity: 0;
      visibility: hidden;
    }

    .modal__close {
      opacity: 1;
      visibility: visible;
      transition: border 0.33s ease-in-out, background 0.33s ease-in-out, transform 0.33s ease-in-out, opacity 0.25s ease-in-out 0.5s, visibility 0s 0.5s;
    }
  }

  &__content {
    display: flex;
    position: relative;
    padding: 48px;
    top: 50%;
    left: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%) translateY(-50%);
    max-width: 840px;
  }

  &__title {
    width: 55vw;
    max-width: 800px;
    min-width: 320px;
    margin-bottom: 24px;
    pointer-events: none;

    img {
      display: block;
      width: 100%;
    }
  }

  &__text {
    font-family: 'Aeonik Pro', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: 0.011em;
    margin: 0 0 24px;
    text-align: center;
  }

  &__loading {
    display: block;
    position: relative;
    background-color: rgba(255,255,255,0.5);
    height: 8px;
    width: 100%;
    max-width: 372px;
    border-radius: 4px;
    overflow: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0s 0.3s;
    will-change: opacity, visibility;
    margin-bottom: 16px;

    span {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image:
        repeating-linear-gradient(
          -45deg,
          #fff,
          #fff 2rem,
          #E70E43 2rem,
          #E70E43 4rem
        );
      background-size: 200% 200%;
      animation: barberpole 4s linear infinite;


    }
  }

  &__begin {
    position: relative;
    color: #151515;
    text-decoration: none;
    appearance: none;
    border: 0 none;
    border-radius: 30px;
    outline: none;
    box-shadow: none;
    background-color: #4CE91A;
    padding: 16px 30px 16px 88px;
    margin: 0;
    font-family: 'Aeonik Pro', sans-serif;
    font-weight: 500;
    font-size: 22px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out 1.4s, visibility 0s 0s;
    will-change: opacity, visibility;
    cursor: pointer;

    img {
      margin-left: 36px;
    }
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 40px;
    right: 40px;
    width: 94px;
    height: 94px;
    border: 1px solid #fff;
    border-radius: 50%;
    appearance: none;
    outline: none;
    box-shadow: none;
    background: transparent;
    opacity: 0;
    visibility: hidden;
    transition: border 0.15s ease-in-out, background 0.15s ease-in-out, transform 0.33s ease-in-out, opacity 0.25s ease-in-out, visibility 0s 0.25s;
    will-change: border, background, transform, opacity, visibility;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: #4CE91A;
      border-color: #4CE91A;
      transform: scale(1.1) rotate(375deg);

      svg {
        fill: #000;
      }
    }

    svg {
      display: block;
      width: 42px;
      height: 42px;
      fill: #fff;
      transition: fill 0.15s ease-in-out;
    }
  }

  &__made {
    position: absolute;
    right: 40px;
    bottom: 48px;
    transition: transform 0.15s ease-in-out;
    will-change: transform;

    &:hover,
    &:focus {
      transform: scale(1.2) rotate(-15deg);
    }
  }
}

@keyframes barberpole {
  100% {
    background-position: 100% 100%;
  }
}