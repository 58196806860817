.mobile {
  display: block;
  width: 100vw;
  height: 100vh;
  background-image:
    repeating-linear-gradient(
      -45deg,
      #fff,
      #fff 2rem,
      #E70E43 2rem,
      #E70E43 4rem
    );
  background-size: 200% 200%;
  animation: barberpole 60s linear infinite;

  &__inner {
    position: absolute;
    top: 20px;
    left: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    background: #151515;
    border-radius: 32px;
    color: #fff;
    overflow: hidden;
  }

  &__content {
    display: flex;
    position: relative;
    padding: 24px;
    top: 50%;
    left: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%) translateY(-50%);
    max-width: 840px;
  }

  &__title {
    width: 40vw;
    max-width: 600px;
    min-width: 200px;
    margin-bottom: 40px;
    pointer-events: none;

    img {
      display: block;
      width: 100%;
    }
  }

  &__text {
    font-family: 'Aeonik Pro', sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.33;
    letter-spacing: 0.011em;
    margin: 0 0 32px;
    text-align: center;
    max-width: 400px;
  }

  &__icon {
    width: 100%;
    max-width: 48px;
    margin-bottom: 32px;
  }

  &__listen {
    position: relative;
    display: flex;
    align-items: center;
    color: #151515;
    text-decoration: none;
    appearance: none;
    border: 0 none;
    border-radius: 30px;
    outline: none;
    box-shadow: none;
    background-color: #4CE91A;
    padding: 10px 24px;
    margin: 0;
    font-family: 'Aeonik Pro', sans-serif;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }
  }

  &__made {
    position: absolute;
    right: 40px;
    bottom: 48px;
    transition: transform 0.15s ease-in-out;
    will-change: transform;

    &:focus {
      transform: scale(1.2) rotate(-15deg);
    }
  }
}

@keyframes barberpole {
  100% {
    background-position: 100% 100%;
  }
}