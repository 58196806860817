.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  will-change: opacity, visibility;
  transition: opacity 0.33s ease-in-out 0.5s, visibility 0s 0s;

  &.is-hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0s 0.2s;
  }

  &__info {
    display: flex;
    align-items: center;
    position: absolute;
    left: 24px;
    bottom: 24px;
    color: #151515;
    text-decoration: none;
    appearance: none;
    border: 0 none;
    border-radius: 30px;
    outline: none;
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.2);
    background: #4CE91A;
    padding: 11px 24px;
    margin: 0;
    font-family: 'Aeonik Pro', sans-serif;
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
    will-change: transform;
    transition: transform 0.15s ease-in-out;

    &:hover {
      transform: scale(1.04);
    }

    span {
      margin-left: 8px;
    }
  }

  &__nav {
    button {
      position: absolute;
      width: 40px;
      height: 40px;
      border: none;
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;
      background-image: url('../../public/assets/arrow.svg');
      cursor: pointer;
      transition: all 0.4s;
      background-size: contain;
      transform-origin: 50% 25%;
      opacity: 1;

      &:hover,
      &:focus {
        opacity: 1;
      }

      &:first-of-type {
        left: 24px;
        transform: rotate(-180deg);
      }

      &:last-of-type {
        right: 24px;
      }
    }
  }
}
