html,
body,
#root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ivory;
}

// Playlist For center item
.playlist {
  * iframe {
    transform-origin: center;
    transform: translateX(-50%) scale(10);
    left: 50%;
    top: 50%;
  }
}